import GlobalMixin from "components/leilao-entrega-itens/globalMixin"
export default {
  name: 'LeilaoEntregaItensMixin',
  mixins: [GlobalMixin],
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  mounted() {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 10)
    //!document.body.classList.contains('propostas-venda-direta-module-body') && document.body.classList.add('propostas-venda-direta-module-body')
    this.erplayout.erpheader.menu.setActiveMenu('leilao')
  },
  destroyed() {
    // document.body.classList.remove('propostas-venda-direta-module-body')
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  beforeDestroy() {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  }
}
