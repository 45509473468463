<template>
  <div class="m-t-lg">
    <div class="divergencias" v-if="result.validator.divergencias">
      {{result.validator.divergencias}} divergência(s) encontrada(s)
    </div>

    <div class="full-width m-t m-b">
      <table class="u-table full-width" cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Status</th>
          <th>Erro</th>
          <th v-for="h in result.validator.headers">{{h}}</th>
        </tr>
        </thead>
        <tbody>
        <tr :class="{'has-errors': item.errors && item.errors.length}" v-for="item in result.validator.items">
          <td>
            <span v-if="item.bem && item.bem.length">{{item.bem[0].descricao}}</span>
            <span v-else>-</span>
          </td>
          <td><span v-if="item.bem && item.bem.length">{{Status.Status[item.bem[0].status].title}}</span><span v-else>-</span></td>
          <td class="error-alert" v-if="item.errors && item.errors.length">{{item.errors.join(', ')}}</td>
          <td v-else>Ok</td>
          <td v-for="(h, k) in result.validator.headers">{{item.originalData[k]}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntregaDocumentoAnaliseImportacao",
  props: ['result']
}
</script>
