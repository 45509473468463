import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'sl.fila-vendas-leilao'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
    {label: 'Data', name: 'data', active: true, sortable: true, ordering: 1},
    {label: 'Leilão', name: 'leilao', active: true, sortable: true, ordering: 2},
    {label: 'Lote', name: 'lote', active: true, sortable: true, ordering: 3},
    {label: 'Bem', name: 'bem', active: true, sortable: true, ordering: 4},
    {label: 'Arrematante', name: 'arrematante', active: true, sortable: true, ordering: 5},
    {label: 'Código', name: 'codigo', active: false, sortable: true, ordering: 5},
    {label: 'Itens', name: 'itens', active: false, sortable: true, ordering: 5},
    {label: 'Ações', name: 'status', active: true, sortable: true, ordering: 6},
], listVersion)
