import http from '../../../utils/services/http'

export const list = (limit, page, filtros, format = null) => {
    let url = '/api/leiloes-entrega-itens?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (format && format !== 'html') {
        config.responseType = 'blob'
        config.timeout = 30000
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const importarItens = (data, exportar = false) => {
    let url = `/api/entregaItens/importar`
    let config = {
        'timeout': 300000
    }
    if (exportar) {
        url = url + '?export=1'
        config.responseType = 'blob'
    }
    return http.post(url, data, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch((response) => {
            return Promise.reject(response)
        })
}

export const executarImportacao = (leilao, importacao) => {
    let url = `/api/leiloes/${leilao}/importacao/${importacao}/executar`
    return http.post(url, {
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch((response) => {
            return Promise.reject(response)
        })
}
