export default function (lotes) {
  let window = 'autoArrematacao'
  !this.$uloc.printView.get(window) && this.$uloc.printView.new({
    wid: window,
    title: 'Auto Negativo de Arrematação 2o Leilão',
    windowClass: 'erp-print',
    contentClass: '',
    props: {
      lotes: lotes.slice().map(l => l && l.id ? l.id : l)
    }
  }, () => import('@/components/pda/print/views/AutoNegativo2leilao'))
    .then((wid) => {
    }) // return wid
}
